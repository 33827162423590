import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },

  {
    path: '/',
    beforeEnter: () => {
      window.location.href = 'https://innovatiq.education/';
    }
  },

  {
    path: '/rcsa',
    name: 'LoginRCSA',
    component: () => import('../views/LoginRCSA.vue')
  },

  {
    path: '/Register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/student/',
    name: 'StudentLayout',
    component: () => import('../layouts/StudentLayout.vue'),
    children: [
      {
        path: '/StudentHome',
        name: 'StudentHome',
        component: () => import('../views/Student/StudentHome.vue')
      },
      {
        path: '/Modules/:idCourse/',
        name: 'Modules',
        component: () => import('../views/Student/Modules.vue'),
        props: true
      },
      {
        path: '/Learning/:idLesson/',
        name: 'Learning',
        component: () => import('../views/Student/Learning.vue'),
        props: true
      },
      {
        path: '/CoverPage/:idActivity/:idLesson',
        name: 'CoverPage',
        component: () => import('../views/Quiz/CoverPage.vue'),
        props: true
      },
      {
        path: '/AnswerQuiz/:idActivity',
        name: 'AnswerQuiz',
        component: () => import('../views/Quiz/AnswerQuiz.vue'),
        props: true
      },
      {
        path: '/ScorePage/:idActivationDetail/:idResult',
        name: 'ScorePage',
        component: () => import('../views/Quiz/ScorePage.vue'),
        props: true
      },


      {
        path: '/TCoverPage/:idActivity/:idActivation',
        name: 'TCoverPage',
        component: () => import('../views/TeacherQuiz/CoverPage.vue'),
        props: true
      },
      {
        path: '/TAnswerQuiz/:idActivity',
        name: 'TAnswerQuiz',
        component: () => import('../views/TeacherQuiz/AnswerTeacherQuiz.vue'),
        props: true
      },
      {
        path: '/TScorePage/:idActivation/:idResult',
        name: 'TScorePage',
        component: () => import('../views/TeacherQuiz/ScorePage.vue'),
        props: true
      },


      {
        path: '/Practices',
        name: 'Practices',
        component: () => import('../views/Student/Practices.vue'),
      },
      {
        path: '/StudentReport',
        name: 'studentReport',
        component: () => import('../views/Student/StudentReport.vue'),
      },
      {
        path: '/StudentTasks',
        name: 'StudentTasks',
        component: () => import('../views/Student/StudentTasks.vue'),
      },
      {
        path: '/StudentQuiz',
        name: 'StudentQuiz',
        component: () => import('../views/Student/StudentQuiz.vue'),
      },
      {
        path: '/TaskRegister/:idActivity/:idActivation',
        name: 'TaskRegister',
        component: () => import('../views/Student/TaskRegister.vue'),
        props: true
      },
      /*       {
              path: '/Certificate',
              name: 'Certificate',
              component: () => import('../views/Student/Certificate.vue'),
              //props: true
            }, */

    ]
  },

  {
    path: '/teacher/',
    name: 'TeacherLayout',
    component: () => import('../layouts/TeacherLayout.vue'),
    children: [
      {
        path: '/TeacherHome',
        name: 'TeacherHome',
        component: () => import('../views/Teacher/TeacherHome.vue')
      },
      {
        path: '/Groups',
        name: 'Groups',
        component: () => import('../views/Teacher/Groups.vue'),
      },
      {
        path: '/StudentList/:idGroup/:sGroupName',
        name: 'StudentList',
        component: () => import('../views/Teacher/StudentList.vue'),
        props: true
      },
      {
        path: '/Courses/:idGroup/:sGroupName',
        name: 'Courses',
        component: () => import('../views/Teacher/Courses.vue'),
        props: true
      },
      {
        path: '/TeacherCourses',
        name: 'TeacherCourses',
        component: () => import('../views/Teacher/TeacherCourses.vue'),
      },
      {
        path: '/ReviewPractices',
        name: 'ReviewPractices',
        component: () => import('../views/Teacher/ReviewPractices.vue'),
      },
      {
        path: '/Reports',
        name: 'Reports',
        component: () => import('../views/Teacher/TeacherReport.vue'),
      },
      {
        path: '/Tasks',
        name: 'Tasks',
        component: () => import('../views/Teacher/Tasks.vue'),
      },
      {
        path: '/ReviewTasks',
        name: 'ReviewTasks',
        component: () => import('../views/Teacher/ReviewTasks.vue'),
      },
      {
        path: '/TaskGroups/:idActivity',
        name: 'taskGroups',
        component: () => import('../views/Teacher/TaskGroups.vue'),
        props: true
      },
      {
        path: '/QuizGroups/:idActivity',
        name: 'quizGroups',
        component: () => import('../views/Teacher/QuizGroups.vue'),
        props: true
      },

      {
        path: '/steps/:idActivity',
        component: () => import('../views/Tasks/NewTask.vue'),
        props: true,
        children: [{
          path: 'general/:idActivity',
          component: () => import('../views/Tasks/Steps/GeneralScreen.vue'),
          props: true
        },
        {
          path: 'tagsScreen/:idActivity',
          component: () => import('../views/Tasks/Steps/TagsScreen.vue'),
          props: true
        },
        {
          path: 'resourcesScreen/:idActivity',
          component: () => import('../views/Tasks/Steps/ResourcesScreen.vue'),
          props: true
        },
        {
          path: 'evaluationScreen/:idActivity',
          component: () => import('../views/Tasks/Steps/EvaluationScreen.vue'),
          props: true
        },
        {
          path: 'creditScreen/:idActivity',
          component: () => import('../views/Tasks/Steps/CreditScreen.vue'),
          props: true
        },
        ]
      },

      {
        path: '/TeacherQuiz',
        name: 'TeacherQuiz',
        component: () => import('../views/Teacher/TeacherQuiz.vue'),
      },
      {
        path: '/TeacherQuestions/:idActivity',
        name: 'TeacherQuestions',
        props: true,
        component: () => import('../views/Teacher/TeacherQuestions.vue'),
      },
      {
        path: '/TeacherQuizPreview',
        name: 'TeacherQuizPreview',
        //props: true,
        component: () => import('../views/Teacher/TeacherQuizPreview.vue'),
      },


    ]
  },

  {
    path: '/admin/',
    name: 'AdminLayout',
    component: () => import('../layouts/AdminLayout.vue'),
    children: [
      {
        path: '/AdminHome',
        name: 'AdminHome',
        component: () => import('../views/Administrator/AdminHome.vue')
      },
      {
        path: '/Customers',
        name: 'Customers',
        component: () => import('../views/Administrator/Customers.vue')
      },
      {
        path: '/Orders/:idCustomer/:sCustomer',
        name: 'Orders',
        component: () => import('../views/Administrator/Orders.vue'),
        props: true
      },
      {
        path: '/AdminContent',
        name: 'AdminContent',
        component: () => import('../views/Administrator/AdminContent.vue')
      },
      {
        path: '/AdvancedSearch',
        name: 'advancedSearch',
        component: () => import("../components/administrator/tools/FindUsersToDelete.vue")
      },
      
      /*       {
              path: '/CodesTool',
              name: 'CodesTool',
              component: () => import('../views/Administrator/CodesTool.vue'),
            },
            {
              path: '/UsersTool',
              name: 'UsersTool',
              component: () => import('../views/Administrator/UsersTools.vue'),
            }, */

      {
        path: '/AdminTools',
        name: 'AdminTools',
        component: () => import('../views/Administrator/AdminTools.vue'),
      },

      {
        path: '/Workshops',
        name: 'Workshops',
        component: () => import('../views/ContentManager/Workshop.vue'),
      },  
      {
        path: '/ContentManager/:idCourse',
        name: 'ContentManager',
        component: () => import('../views/ContentManager/ContentManager.vue'),
        props: true
      },    
      {
        path: '/AdminPractices',
        name: 'AdminPractices',
        component: () => import('../views/Administrator/Practices.vue'),
        props: true
      },  
      {
        path: '/AdminQuiz',
        name: 'AdminQuiz',
        component: () => import('../views/Administrator/AdminQuiz/AdminQuiz.vue'),
        props: true
      },
      {
        path: '/AdminQuestions/:idActivity',
        name: 'AdminQuestions',
        component: () => import('../views/Administrator/AdminQuiz/AdminQuestions.vue'),
        props: true
      },      
    ]
  },


  {
    path: '/supervisor/',
    name: 'SupervisorLayour',
    component: () => import('../layouts/SupervisorLayout.vue'),
    children: [
      {
        path: '/SupervisorHome',
        name: 'SupervisorHome',
        component: () => import('../views/Supervisor/SupervisorHome.vue')
      },
      {
        path: '/DetailDashboard/:idCustomer',
        name: 'DetailDashboard',
        component: () => import('../views/Supervisor/DetailDashboard.vue'),
        props: true
      },
      {
        path: '/DashboardGroupDetail/:idGroup',
        name: 'DashboardGroupDetail',
        component: () => import('../views/Supervisor/GroupDetail.vue'),
        props: true
      },
      {
        path: '/StudentDashboardDetail/:idGroup/:idCourse',
        name: 'StudentDashboardDetail',
        component: () => import('../views/Supervisor/StudentDashboardDetail.vue'),
        props: true
      },
    ]
  }

]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router